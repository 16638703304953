import * as Sentry from '@sentry/node'
import { Integrations } from "@sentry/tracing"

export const init = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA,
      autoSessionTracking: true,
      integrations: [
        new Integrations.BrowserTracing(),
      ],
      tracesSampleRate: 0.25,
    })
    // console.log('Sentry is running',process.env.NEXT_PUBLIC_SENTRY_DSN)
  }
}